































































import axios from 'axios'
import NavigateBack from '@/components/shared/NavigateBack.vue'
import AddProductsModal from '@/components/products/AddProductsModal.vue'
import { getCurrentUser } from '@/lib/user'
import { getProduct } from '@/lib/product'
import AddFulfillmentGroupImage from '@/components/fulfillment/AddFulfillmentGroupImage.vue'
import { Product } from '../../../../shared/types/types'
import Vue from 'vue'

export default Vue.extend({
  name: 'EditFulfillment',
  components: {
    AddFulfillmentGroupImage,
    NavigateBack,
  },
  data() {
    return {
      title: 'Fulfillment',
      imageUrl: '',
      products: [] as Product[],
    }
  },
  async created() {
    const { fulfillmentGroupId } = this.$route.params

    const currentUser = await getCurrentUser()
    const { data } = await axios.get(
      `${process.env.VUE_APP_FULFILLMENT_ENDPOINT}/groups/${fulfillmentGroupId}`,
      {
        headers: {
          Authorization: currentUser.signInUserSession.idToken.jwtToken,
        },
      }
    )

    const products = (await Promise.all(
      (data.productIds as string[]).map((productId) => getProduct(productId))
    )) as Product[]

    this.products = products
    this.imageUrl = data.imageUrl
    this.title = data.title
  },
  methods: {
    openAddProductsModal() {
      this.$buefy.modal.open({
        parent: this,
        component: AddProductsModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          existingProductIds: this.products.map((product) => product.productId),
        },
        events: {
          save: async (
            selectedProductsIds: string[],
            closeModal: () => void
          ) => {
            const products = (await Promise.all(
              selectedProductsIds.map((productId) => getProduct(productId))
            )) as Product[]

            this.products = products

            closeModal()
          },
        },
      })
    },
    updateImage(imageUrl: string) {
      this.imageUrl = imageUrl
    },
    removeProduct(product: Product) {
      this.products = this.products.filter(
        (p) => p.productId !== product.productId
      )
    },
    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: 'Deleting fulfillment group',
        message:
          'Are you sure you want to <b>delete</b> this fulfillment group? This action cannot be undone.',
        confirmText: 'Delete Fulfillment Group',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          const { fulfillmentGroupId } = this.$route.params
          const currentUser = await getCurrentUser()

          await axios.delete(
            `${process.env.VUE_APP_FULFILLMENT_ENDPOINT}/groups/${fulfillmentGroupId}`,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: currentUser.signInUserSession.idToken.jwtToken,
              },
            }
          )

          this.$router.push(`/fulfillment/groups`)
          this.$buefy.toast.open('Fulfillment group deleted!')
        },
      })
    },
    async handleSave() {
      const { fulfillmentGroupId } = this.$route.params
      const currentUser = await getCurrentUser()

      const { data } = await axios.patch(
        `${process.env.VUE_APP_FULFILLMENT_ENDPOINT}/groups/${fulfillmentGroupId}`,
        {
          title: this.title,
          productIds: this.products.map((product) => product.productId),
          ...(this.imageUrl !== '' ? { imageUrl: this.imageUrl } : {}),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: currentUser.signInUserSession.idToken.jwtToken,
          },
        }
      )

      this.$router.push(`/fulfillment/groups/${data.fulfillmentGroupId}`)
    },
  },
})
